//import manager from '@/utils/manager'
import api from '@/utils/api'

export function pay(params) {
    params.project_id = 'dkxyxcxh5';
    params.type = 2;
    return api.post('wx/v1/official/common/wxpay', params)
}

export function accountInfo(params) {
    return api.get('wx/v1/account', { params:params })
}

export function roleInfo(params) {
    return api.get('wx/v1/darkxiyouxcx/check_role', { params:params })
}

export function gameList(params) {
    // return api.get('wx/v1/wolf/game', { params:params })
    return api.get('wx/v1/darkxiyouxcx/game', { params:params })
}

export function serverList(params) {
    return api.get('wx/v1/darkxiyouxcx/server', { params:params })
}

export function roleList(params) {
    return api.get('wx/v1/darkxiyouxcx/role', { params:params })
}

export function commonCreateOrder(params) {
    return api.post('wx/v1/darkxiyouxcx/common/jzac', params)
}

//支付包支付或paypel支付
export function payMix(params) {
    // return api.post('wx/v1/pay/mix', params)
    return api.post('wx/v1/official_account/pay', params)
}

//公众号微信支付
export function payWxRedirect(orderNum) {
    // return api.post('wx/v1/wolf/pay/wxRedirect', { order_num: orderNum })
    //    最新版公众号微信支付
    return api.post('wx/v1/official/common/wxpay', { order_num: orderNum, type: 1, project_id: 'darkxiyouxcx' })
}

export function stripeMoneyList(params) {
    return api.post('stripe/moneyList', params)
}
